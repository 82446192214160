import * as Sentry from "@sentry/nuxt";

Sentry.init({
  dsn: "https://b9b5fdf420256276434978f550d09369@o4507174914097152.ingest.de.sentry.io/4507840004882512",
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracePropagationTargets: ["localhost", "https://newfront.genotic.com"],
});
